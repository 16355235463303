<template>
  <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
    <g fill="currentColor" fill-rule="evenodd" transform="translate(0 2)">
      <path
        fill="currentColor"
        fill-rule="nonzero"
        d="M4.8517924,3.28731942 C4.8517924,2.38844302 5.1599786,1.61797753 5.80203317,0.975922953 C6.46976993,0.308186196 7.24023542,0 8.13911182,0 C9.03798823,0 9.8341359,0.308186196 10.4761905,0.975922953 C11.1182451,1.64365971 11.4521134,2.4141252 11.4521134,3.28731942 C11.4521134,4.21187801 11.1439272,4.9823435 10.4761905,5.62439807 C9.80845372,6.26645265 9.03798823,6.60032103 8.13911182,6.60032103 C6.28999465,6.60032103 4.8517924,5.11075441 4.8517924,3.28731942 L4.8517924,3.28731942 L4.8517924,3.28731942 Z M14.8421616,12.3274478 L14.8421616,16 L1.33333333,16 L1.33333333,12.3274478 C1.33333333,10.0160514 4.36383093,8.06420546 8.08774746,8.06420546 C11.7602996,8.06420546 14.8421616,10.0160514 14.8421616,12.3274478 L14.8421616,12.3274478 Z"
      />
    </g>
  </svg>
</template>
